import * as React from 'react'
import { Link } from 'gatsby'
import { LocaleContext } from '../components/layout'
import LocalizedLink from '../components/localized-link'
// styles

// markup
const NotFoundPage = ({ pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <main>
      <title>Not found</title>
      <h1>Page not found</h1>
      <p>
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <LocalizedLink to="/">Go home</LocalizedLink>.
      </p>
    </main>
  )
}

export default NotFoundPage
